/** 
 * ===================================================================
 * main js
 *
 * ------------------------------------------------------------------- 
 */ 

(function($) {

	"use strict";

	/*---------------------------------------------------- */
	/* Preloader
	------------------------------------------------------ */ 
    $(window).on("load",function() {

      // will first fade out the loading animation 
    	//$("#loader").fadeOut("slow", function(){

        // will fade out the whole DIV that covers the website.
        //$("#preloader").delay(300).fadeOut("slow");

      //});

  	});


  	/*---------------------------------------------------- */
  	/* FitText Settings
  	------------------------------------------------------ */
  	setTimeout(function() {

   	$('#intro h1').fitText(1, { minFontSize: '42px', maxFontSize: '84px' });

  	}, 100);


	/*---------------------------------------------------- */
	/* FitVids
	------------------------------------------------------ */ 
  	$(".fluid-video-wrapper").fitVids();


	/*---------------------------------------------------- */
	/* Owl Carousel
	------------------------------------------------------ */ 
	$("#owl-slider").owlCarousel({
        navigation: false,
        pagination: true,
        itemsCustom : [
	        [0, 1],
	        [700, 2],
	        [960, 3]
	     ],
        navigationText: false
    });


	/*----------------------------------------------------- */
	/* Alert Boxes
  	------------------------------------------------------- */
    $('.alert-box').on('click', '.close', function() {
        $(this).parent().fadeOut(500);
    });


	/*----------------------------------------------------- */
	/* Stat Counter
  	------------------------------------------------------- */
    var statSection = $("#stats"),
       stats = $(".stat-count");

    statSection.waypoint({

   	handler: function(direction) {

      	if (direction === "down") {       		

            stats.each(function () {
               var $this = $(this);

               $({ Counter: 0 }).animate({ Counter: $this.text() }, {
                duration: 4000,
                easing: 'swing',
                step: function (curValue) {
                    $this.text(Math.ceil(curValue));
                    }
                });
            });

       	} 

       	// trigger once only
       	this.destroy();      	

		},
			
		offset: "90%"
	
	});	


	/*---------------------------------------------------- */
	/*	Masonry
	------------------------------------------------------ */
	var containerProjects = $('#folio-wrapper');

	containerProjects.imagesLoaded( function() {

		containerProjects.masonry( {		  
		  	itemSelector: '.folio-item',
		  	resize: true 
		});

	});


	/*----------------------------------------------------*/
	/*	Modal Popup
	------------------------------------------------------*/
    $('.item-wrap a').magnificPopup({

      type:'inline',
      fixedContentPos: false,
      removalDelay: 300,
      showCloseBtn: false,
      mainClass: 'mfp-fade'

    });

    $(document).on('click', '.popup-modal-dismiss', function (e) {
   	    e.preventDefault();
   	    $.magnificPopup.close();
    });

	
	/*-----------------------------------------------------*/
  	/* Navigation Menu
    ------------------------------------------------------ */
    var toggleButton = $('.menu-toggle'),
       nav = $('.main-navigation');

    // toggle button
    toggleButton.on('click', function(e) {

		e.preventDefault();
		toggleButton.toggleClass('is-clicked');
		nav.slideToggle();

	});

    // nav items
  	nav.find('li a').on("click", function() {   

   	// update the toggle button 		
   	toggleButton.toggleClass('is-clicked'); 
   	// fadeout the navigation panel
   	nav.fadeOut();   		
   	     
  	});


    /*---------------------------------------------------- */
  	/* Highlight the current section in the navigation bar
  	------------------------------------------------------ */
	var sections = $("section"),
	navigation_links = $("#main-nav-wrap li a");	

	sections.waypoint( {

       handler: function(direction) {

		   var active_section;

			active_section = $('section#' + this.element.id);

			if (direction === "up") active_section = active_section.prev();

			var active_link = $('#main-nav-wrap a[href="#' + active_section.attr("id") + '"]');			

         navigation_links.parent().removeClass("current");
			active_link.parent().addClass("current");

		}, 

		offset: '25%'
	});


	/*---------------------------------------------------- */
  	/* Smooth Scrolling
  	------------------------------------------------------ */
  	$('.smoothscroll').on('click', function (e) {
	 	
	 	e.preventDefault();

   	    var target = this.hash,
    	$target = $(target);
    	$('html, body').stop().animate({
       	    'scrollTop': $target.offset().top
        }, 800, 'swing', function () {
      	window.location.hash = target;
      });

  	});  
  

    /*---------------------------------------------------- */
	/*  Placeholder Plugin Settings
	------------------------------------------------------ */ 
	$('input, textarea, select').placeholder();


  	/*---------------------------------------------------- */
	/*	contact form
	------------------------------------------------------ */

    function contact(data) {
        var sLoader = $('#submit-loader');
        $.ajax({
            method: "POST",
            url: "contacto",
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: data,
            beforeSend: function() {
                sLoader.fadeIn();
            },
            success: function(response) {
                sLoader.fadeOut();
                if (response === 'OK') {
                    $('#message-warning').hide();
                    $('#message-success').fadeIn().delay(5000).fadeOut();
                    emptyFields([$('#contactName'), $('#contactEmail'), $('#contactMessage')]);
                } else {
                    $('#message-warning').html(response).fadeIn();
                }
            },
            error: function() {
                sLoader.fadeOut();
                $('#message-warning').html("Ups! Algo ha ido mal, inténtalo pasado unos minutos").fadeIn();
            }
        });
    }

    $('#privacidad').on('change', function() {
        $('#submitForm').prop('disabled', !this.checked);
    });

    $('#contactEmail').on('blur', function(){
        var email = $(this).val().trim();
        if (!validateEmail(email)) {
            $('#message-warning').html("Por favor, introduce un email válido").fadeIn();
        } else {
            $('#message-warning').fadeOut();
        }
    });

	/* local validation */
    $('#contactForm').validate({
        /* submit via ajax */
        submitHandler: function(form) {
            contact($(form).serialize());
        }
    });

    $('.submitForm').on('click', function () {

        // Verificar si el checkbox de privacidad está marcado
        if (!$('#privacidad').is(':checked')) {
            $('#message-warning').html("Debes aceptar la política de privacidad.").fadeIn();
            return false;
        }

        // Validar el reCAPTCHA
        grecaptcha.ready(function () {
            grecaptcha.execute(window.recaptchaSiteKey, { action: 'submit' }).then(function (token) {
                if (!token) {
                    $('#message-warning').html("Por favor, completa el reCAPTCHA.").fadeIn();
                    return false;
                }
        
                //console.log('reCAPTCHA token:', token);
        
                // Validar el nombre
                if ($('#contactName').val().trim().length < 2) {
                    $('#message-warning').html("Por favor, introduce tu nombre.").fadeIn();
                    return false;
                }

                // Validar el email
                if (!validateEmail($('#contactEmail').val().trim())) {
                    $('#message-warning').html("Por favor, introduce un email válido.").fadeIn();
                    return false;
                }

                // Validar el mensaje
                if ($('#contactMessage').val().trim() === '') {
                    $('#message-warning').html("Por favor, introduce un mensaje.").fadeIn();
                    return false;
                }

                // Enviar los datos si todo es válido
                contact({
                    contactName: $('#contactName').val().trim(),
                    contactEmail: $('#contactEmail').val().trim(),
                    contactMessage: $('#contactMessage').val().trim(),
                    recaptcha: token // Enviar el token del reCAPTCHA
                });
            });
        });
    });

    function emptyFields(fields) {
        fields.forEach(function(field) {
            emptyField(field);
        });
    }

    function emptyField(field) {
        field.val('');
    }

    function validateEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }


 	/*----------------------------------------------------- */
  	/* Back to top
    ------------------------------------------------------- */
	const pxShow = 300; // height on which the button will show
	const fadeInTime = 400; // how slow/fast you want the button to show
	var fadeOutTime = 400; // how slow/fast you want the button to hide
	var scrollSpeed = 300; // how slow/fast you want the button to scroll to top. can be a value, 'slow', 'normal' or 'fast'

    // Show or hide the sticky footer button
	jQuery(window).scroll(function() {

		if (!( $("#header-search").hasClass('is-visible'))) {

            const scrollTop = jQuery(window).scrollTop();
            const goTopButton = jQuery("#go-top");

            if (scrollTop >= pxShow) {
                goTopButton.stop(true, true).fadeIn(fadeInTime).addClass("active");
            } else {
                goTopButton.stop(true, true).fadeOut(fadeOutTime).removeClass("active");
            }

		}		

	});

})(jQuery);

$(document).ready(function () {
    $('#cookies').delay(3000).fadeIn(200);

    //aceptar las cookies
    $('#cookies-btn, #cookies-aceptar-todo-btn').click(function () {
        $.ajax({
            type: 'POST',
            url: '/aviso-cookies',
            data: { todo: 1 },
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function success(respuesta, estado, req) {
                if (respuesta == "OK") {
                    location.reload();
                }
            }
        });
    });

    //rechazar las cookies
    $('#cookies-rechazar-btn, #cookies-rechazar-todo-btn').on('click', function () {
        $.ajax({
            type: 'POST',
            url: '/aviso-cookies',
            data: { rechazar: 1 },
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function success(respuesta, estado, req) {
                if (respuesta == "OK") {
                    location.reload();
                }
            }
        });
    });


    $('#cookies-guardar-btn').click(function () {
        $.ajax({
            type: 'POST',
            url: '/aviso-cookies',
            data:  $('#frm_cookies_setup').serialize(),
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function success(respuesta, estado, req) {
                if (respuesta == "OK") {
                    location.reload();
                }
            }
        });
    });

    $('#cookies-configura-btn .btn').on('click', function () {
        $(this).hide();
        $('#cookies-configura-setup').show();
    });

    $('#cookies-setup-btn').click(function () {
        $('#cookies-btn').hide();
        $('#cookies-rechazar-btn').hide();
        $('#cookies-setup-btn').hide();

        // Para mostrar el contenedor de la configuración en la página de la normativa de Cookies
        $('#cookies-normativa').show();

        // Para mostrar la opciones disponibles de configuraciones
        $('#cookies-setup').show();

    });

    $('#cookies-cancelar-btn').on('click', function () {
        //$('#cookies-normativa').hide();
        $('#cookies-configura-setup').hide();
        $('#cookies-configura-btn .btn').show();

        // Restauramos la configuración con los valores anteriomente establecidos
        $('#frm_cookies_setup input[type="checkbox"]').each(function () {
            if ($(this).data("inicial")) {
                $(this).prop("checked", true);
            } else {
                $(this).prop("checked", false);
            }
        });
    });

});
